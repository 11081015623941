import { Link } from "@remix-run/react";
import { Button, LoginLayout } from "~/components";
import { loader } from "./.server";

export { loader };

export default function Login() {
  return (
    <LoginLayout>
      <div className="flex flex-col gap-8 px-2 py-6 md:py-16">
        <h2 className="text-center text-2xl font-normal tracking-tighter text-black">
          Use your Google account
          <br />
          to start using Moneta.
        </h2>
        <Button size="large" asChild>
          <Link to="/auth/google/login" reloadDocument>
            Sign in with Google
          </Link>
        </Button>
      </div>
    </LoginLayout>
  );
}
